import React from "react";
import { Helmet } from "react-helmet";

export const MetaComponent = ({
  title = "Verak - Protect your business today",
  description = "Protect your business today. Starting at just ₹8 per day.",
  image = "mlp-3",
  url = "",
  loadYellowAiScript = true,
  loadFbPixelScript = true,
}) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description}></meta>
      <meta
        property="og:image"
        content={`https://verak-assets.s3.ap-south-1.amazonaws.com/meta-${image}.png`}
      ></meta>
      <meta property="og:url" content={`www.verak.in/${url}`}></meta>
      <meta property="og:site_name" content="Verak Insurance"></meta>
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:title" content={title}></meta>
      <meta
        name="twitter:image"
        content={`https://verak-assets.s3.ap-south-1.amazonaws.com/meta-${image}.png`}
      ></meta>
      <meta name="twitter:description" content={description}></meta>
      <meta name="twitter:url" content={`www.verak.in/${url}`}></meta>
      {loadYellowAiScript && <script defer src="/scripts/ymConfig.min.js" />}
      {loadFbPixelScript && <script defer src="/scripts/fbPixel.min.js" />}
    </Helmet>
  );
};
