import { createGlobalStyle } from "styled-components"

const ApplyInterFont = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Inter !important;
    -webkit-tap-highlight-color: transparent;
  }
`

export default ApplyInterFont