import { isBrowser } from "./browserUtils"

const captureQueryParams = () => {
    if (isBrowser) {
        const queryParams = window.location.search
        if (queryParams && window.location.pathname.includes('/campaigns')) {
            const queryParamsMap = new URLSearchParams(queryParams)
            // store query params map in session store
            window.sessionStorage.setItem('queryParams', JSON.stringify(queryParams))
            return queryParamsMap
        }
    }
    return null
}

export const getQueryParams = () => {
    if (isBrowser) {
        const storedQueryParam = window.sessionStorage.getItem('queryParams')
        if (storedQueryParam) {
            const queryString = JSON.parse(storedQueryParam)
            return new URLSearchParams(queryString) || null
        }
        return captureQueryParams()
    }
    return null
}

export const extractQueryParams = () => {
    if (isBrowser) {
        const queryParams = window.location.search
        const queryParamsMap = new URLSearchParams(queryParams)
        return queryParamsMap
    }
    return null
}